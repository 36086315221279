import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import InputMask from 'react-input-mask';
import { FaArrowRight, FaArrowLeft } from 'react-icons/fa';

// Função para validar CPF
const validateCPF = (cpf) => {
  cpf = cpf.replace(/[^\d]+/g, '');
  if (cpf.length !== 11 || /^(\d)\1+$/.test(cpf)) {
    return false;
  }
  let soma = 0;
  let resto;
  for (let i = 1; i <= 9; i++) soma += parseInt(cpf.substring(i - 1, i)) * (11 - i);
  resto = (soma * 10) % 11;
  if (resto === 10 || resto === 11) resto = 0;
  if (resto !== parseInt(cpf.substring(9, 10))) return false;
  soma = 0;
  for (let i = 1; i <= 10; i++) soma += parseInt(cpf.substring(i - 1, i)) * (12 - i);
  resto = (soma * 10) % 11;
  if (resto === 10 || resto === 11) resto = 0;
  if (resto !== parseInt(cpf.substring(10, 11))) return false;
  return true;
};

const Step6 = () => {
  const navigate = useNavigate();
  const [initialValues, setInitialValues] = useState({
    cpf: '',
  });

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: Yup.object({
      cpf: Yup.string()
        .required('Campo obrigatório')
        .test('cpf-valido', 'CPF inválido', (value) => validateCPF(value)),
    }),
    onSubmit: (values) => {
      localStorage.setItem('step6', JSON.stringify(values));
      navigate('/step7');
    },
  });

  const getStepData = () => {
    let step_data = localStorage.getItem('step6');
    if (step_data !== null) {
      const newInitialValues = JSON.parse(step_data);
      setInitialValues(newInitialValues);
    }
  };

  useEffect(() => {
    getStepData();
  }, []);

  return (
    <form onSubmit={formik.handleSubmit} style={{ width: '90%' }}>
      <h2>Informações Pessoais</h2>
      <div className="form-group">
        <label htmlFor="cpf">Qual seu CPF?</label>
        <InputMask
          id="cpf"
          name="cpf"
          type="text"
          className="form-control"
          mask="999.999.999-99"
          onChange={formik.handleChange}
          value={formik.values.cpf}
        />
        {formik.errors.cpf ? <div className="text-danger">{formik.errors.cpf}</div> : null}
      </div>
      <div className="form-group button-group" style={{ marginTop: 20, textAlign: 'right' }}>
        <button
          type="button"
          onClick={() => {
            if (localStorage.getItem('step3') !== null) {
              const step3 = JSON.parse(localStorage.getItem('step3'));
              
              if (step3.user_type !== 'Agenciador') {
                navigate('/step5');
              } else {
                navigate('/step3');  
              }
            }
          }}
          className="btn btn-secondary"
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginRight: '15px',
          }}
        >
          <FaArrowLeft style={{ marginRight: '5px' }} /> Voltar
        </button>
        <button
          type="submit"
          className="btn btn-primary"
          style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        >
          Próximo <FaArrowRight style={{ marginLeft: '5px' }} />
        </button>
      </div>
    </form>
  );
};

export default Step6;
