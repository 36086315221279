import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import InputMask from 'react-input-mask';
import { FaArrowRight, FaArrowLeft } from 'react-icons/fa';
import { cnpj as cnpjValidator } from 'cpf-cnpj-validator';

const Step7 = () => {
  const navigate = useNavigate();
  const step3 = JSON.parse(localStorage.getItem('step3'));
  const [initialValues, setInitialValues] = useState({
    cnpj: '',
  });

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: Yup.object({
      cnpj: Yup.string()
        .required('Campo obrigatório')
        .test('is-valid-cnpj', 'CNPJ inválido', value => cnpjValidator.isValid(value)),
    }),
    onSubmit: (values) => {
      localStorage.setItem('step7', JSON.stringify(values));
      navigate('/step8');
    },
  });

  const getStepData = () => {
    let step_data = localStorage.getItem('step7');
    if (step_data !== null) {
      const newInitialValues = JSON.parse(step_data);
      setInitialValues(newInitialValues);
    }
  };

  useEffect(() => {
    getStepData();
  }, []);

  return (
    <form onSubmit={formik.handleSubmit} style={{ width: '90%' }}>
      <h2>Informações Profissionais</h2>
      <div className="form-group">
        <label htmlFor="cnpj">Qual o CNPJ da sua empresa?</label>
        <InputMask
          id="cnpj"
          name="cnpj"
          type="text"
          className="form-control"
          mask="99.999.999/9999-99"
          onChange={formik.handleChange}
          value={formik.values.cnpj}
        />
        {formik.errors.cnpj ? <div className="text-danger">{formik.errors.cnpj}</div> : null}

        {step3.user_type === 'Transportador' && (<label>Se seu CPF estiver no quadro societário da empresa será possível cooperar.</label>)}
      </div>

      <div className="form-group button-group" style={{ marginTop: 20, textAlign: 'right' }}>
        <button
          type="button"
          onClick={() => {
            navigate('/step6');
          }}
          className="btn btn-secondary"
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginRight: '15px',
          }}
        >
          <FaArrowLeft style={{ marginRight: '5px' }} /> Voltar
        </button>
        <button
          type="submit"
          className="btn btn-primary"
          style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        >
          Próximo <FaArrowRight style={{ marginLeft: '5px' }} />
        </button>
      </div>
    </form>
  );
};

export default Step7;
