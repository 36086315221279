import React from 'react';

const Thanks = () => {
  return (
    <div>
      <h4 className='text-center'>Suas informações foram salvas</h4>
      <h2 className='text-center'>Muito obrigado!</h2>

    </div>
  );
};

export default Thanks;
