import React, { useState, useEffect } from 'react';
import axios from 'axios';

const Launch = () => {
    const [daysRemaining, setDaysRemaining] = useState('');

    function getName() {
        return JSON.parse(localStorage.getItem('step1'));
    }

    function getValuesLocalStorage() {
        let trackerList = [JSON.parse(localStorage.getItem('step4'))];
        let companyList = [JSON.parse(localStorage.getItem('step5'))];
        let signatureList = [JSON.parse(localStorage.getItem('signature'))];

        trackerList = trackerList[0].tracker === '' ? [] : trackerList;
        companyList = companyList[0].company === '' ? [] : companyList;
        signatureList = signatureList[0].token === '' ? [] : signatureList;

        const allData = {
            ...JSON.parse(localStorage.getItem('step1')),
            ...JSON.parse(localStorage.getItem('step2')),
            ...JSON.parse(localStorage.getItem('step3')),
            trackerList,
            companyList,
            ...JSON.parse(localStorage.getItem('step6')),
            ...JSON.parse(localStorage.getItem('step7')),
            ...JSON.parse(localStorage.getItem('step8')),
            ...JSON.parse(localStorage.getItem('step9')),
            ...JSON.parse(localStorage.getItem('step10')),
            signatureList
        };

        return allData;
    };

    async function sendToBackend(allData) {
        try {
            var data = new FormData();
            let dados = allData;
            data.append('dados', JSON.stringify(dados));

            const response = await axios.post('https://api.automov.com.br/api/leads/add.json', data, {
            //const response = await axios.post('http://localhost:8765/api/leads/add.json', data, {
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'multipart/form-data'
                }
            });

            clearLocalStorage();
        } catch (error) {
            alert('Houve um erro ao salvar suas informações. Por favor, tente novamente.');
        }
    }

    function clearLocalStorage() {
        localStorage.removeItem('step1');
        localStorage.removeItem('step2');
        localStorage.removeItem('step3');
        localStorage.removeItem('step4');
        localStorage.removeItem('step5');
        localStorage.removeItem('step6');
        localStorage.removeItem('step7');
        localStorage.removeItem('step8');
        localStorage.removeItem('step9');
        localStorage.removeItem('step10');
        localStorage.removeItem('signature');
    }

    function calculateDaysRemaining() {
        const [day, month, year] = '03/03/2025'.split('/').map(Number);
        const target = new Date(year, month - 1, day);
        const today = new Date();
        
        // Calcula a diferença em milissegundos e converte para dias
        const diffTime = target - today;
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  
        // Atualiza o estado com a contagem regressiva no formato "X DIAS" ou "1 DIA"
        if (diffDays > 1) {
          setDaysRemaining(`${diffDays} DIAS`);
        } else if (diffDays === 1) {
          setDaysRemaining(`1 DIA`);
        } else {
          setDaysRemaining(`EXPIRADO`);
        }
    };

    useEffect(() => {
        if (getName()) {
            const allData = getValuesLocalStorage();
            console.log(allData);

            if (allData) {
                sendToBackend(allData);
            }
        }

        calculateDaysRemaining();
        setInterval(calculateDaysRemaining, 86400000);

    }, []);

    return (
        <form style={{ width: '90%' }}>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100vh', margin: '0px', padding: '0px', textAlign: 'center' }}>
                <h2 className='nos-estamos'>Nós estamos</h2>
                <h2 className='a-todo-vapor'>a todo vapor.</h2>
                <div style={{ backgroundColor: '#0a6265', borderRadius: '100px', margin: '0px', padding: '0px'}}>
                    <h2 className='faltam'>FALTAM:</h2>
                </div>
                <h2 className='dias'>{daysRemaining}</h2>
                <h2 className='para-o-lancamento'>PARA O LANÇAMENTO</h2>
                <h2 className='aguardem' style={{  }}>Aguardem.</h2>

            </div>
        </form>
    );
};

export default Launch;
