import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import Brazil from "@react-map/brazil";
import { FaArrowRight, FaArrowLeft } from 'react-icons/fa';

const stateAbbreviations = {
  "Acre": "AC",
  "Alagoas": "AL",
  "Amapá": "AP",
  "Amazonas": "AM",
  "Bahia": "BA",
  "Ceará": "CE",
  "Distrito Federal": "DF",
  "Espírito Santo": "ES",
  "Goiás": "GO",
  "Maranhão": "MA",
  "Mato Grosso": "MT",
  "Mato Grosso do Sul": "MS",
  "Minas Gerais": "MG",
  "Pará": "PA",
  "Paraíba": "PB",
  "Paraná": "PR",
  "Pernambuco": "PE",
  "Piauí": "PI",
  "Rio de Janeiro": "RJ",
  "Rio Grande do Norte": "RN",
  "Rio Grande do Sul": "RS",
  "Rondônia": "RO",
  "Roraima": "RR",
  "Santa Catarina": "SC",
  "São Paulo": "SP",
  "Sergipe": "SE",
  "Tocantins": "TO"
};

const Step2 = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [name, setName] = useState('...');
    const [mapSize, setMapSize] = useState(600);

    const formik = useFormik({
        initialValues: {
            regions: []
        },
        validationSchema: Yup.object({
            regions: Yup.array().min(1, 'Selecione pelo menos uma região').required('Campo obrigatório')
        }),
        enableReinitialize: true,
        onSubmit: values => {
            localStorage.setItem('step2', JSON.stringify(values));
            navigate('/step3');
        }
    });

    const handleRegionClick = (stateName) => {
        const stateAbbr = stateAbbreviations[stateName];
        let states = [...formik.values.regions];

        if (states.includes(stateAbbr)) {
            states = states.filter(state => state !== stateAbbr);
        } else {
            states.push(stateAbbr);
        }

        formik.setFieldValue('regions', states);
    };

    const getName = async () => {
        let leadName = JSON.parse(localStorage.getItem('step1'));
        if ( leadName !== null ) {
            setName(leadName.name);

        }
    };

    const getStepData = () => {
        let step_data = localStorage.getItem('step2');
        if (step_data !== null) {
            localStorage.removeItem('step2');
        }
    };

    useEffect(() => {
        getName();
        getStepData();

        const handleResize = () => {
            if (window.innerWidth <= 500) {
                setMapSize(window.innerWidth - 50);
            } else {
                setMapSize(600);
            }
        };

        window.addEventListener('resize', handleResize);

        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <form onSubmit={formik.handleSubmit} style={{ width: '90%', display: location.pathname === '/step2' ? 'block' : 'none' }}>
            <h2>Área de Atuação</h2>
            <div className="form-group">
                <label>{name}, em quais regiões você atua?</label>
                <div className="map-container">
                    <Brazil
                        type='select-multiple'
                        mapColor="#CCC"
                        strokeColor="#000"
                        strokeWidth={1}
                        hoverColor={"#f7f7f7"}
                        selectColor={"#333"}
                        onSelect={handleRegionClick}
                        hints={true}
                        size={mapSize}
                    />
                </div>
                {formik.errors.regions ? <div className="text-danger">{formik.errors.regions}</div> : null}
            </div>
            <div className="form-group button-group" style={{ marginTop: 20, textAlign: 'right' }}>
                <button type="button" onClick={() => { navigate('/'); }} className="btn btn-secondary" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginRight: '15px' }}>
                    <FaArrowLeft style={{ marginRight: '5px' }} /> Voltar
                </button>
                <button type="submit" className="btn btn-primary" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    Próximo <FaArrowRight style={{ marginLeft: '5px' }} />
                </button>
            </div>
        </form>
    );
};

export default Step2;
