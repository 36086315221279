import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import Empty from './components/Empty';
import Step1 from './components/Step1';
import Step2 from './components/Step2';
import Step3 from './components/Step3';
import Step4 from './components/Step4';
import Step5 from './components/Step5';
import Step6 from './components/Step6';
import Step7 from './components/Step7';
import Step8 from './components/Step8';
import Step9 from './components/Step9';
import Step10 from './components/Step10';
import Thanks from './components/Thanks';
import Signature from './components/Signature';
import Launch from './components/Launch';

function DivBackgroundImage() {
  const location = useLocation();
  const [divClass, setDivClass] = useState(null);

  useEffect(() => {
    if (location.pathname === '/signature') {
      setDivClass('');
    } else {
      //setDivClass('background-image');
      setDivClass('');
    }
  }, [location.pathname]);

  return (
    <div className={divClass}/>
  );
}

function DivBackgroundImageHeader() {
  const location = useLocation();
  const [divContent, setDivContent] = useState({
    className: '',
    additionalElement: null
  });  

  useEffect(() => {
    if (location.pathname === '/signature') {
      setDivContent({
        className: 'header',
        additionalElement: <><img src="/assets/imgs/header-bg-ctv.png" alt="Header" className="header-image"/></>
      });
    } else {
      setDivContent({
        className: 'header',
        additionalElement: <><img src="/assets/imgs/header-bg-ctv.png" alt="Header" className="header-image"/></>
        //additionalElement: <><img src="/assets/imgs/header-bg.png" alt="Header" className="header-image"/></>
      });
    }
  }, [location.pathname]);

  return (
    <div className={divContent.className}>
      {divContent.additionalElement}
    </div>
  );
}

function DivBackgroundContent() {
  const location = useLocation();
  const [divContent, setDivContent] = useState({
    className: '',
    additionalElement: null
  });

  useEffect(() => {
    if (location.pathname === '/signature') {
      setDivContent({
        className: 'background-content',
        additionalElement: <><img src="/assets/imgs/side-image-ctv.png" alt="Logotipo" className="logo" /></>
      });
    } else {
      setDivContent({
        className: 'background-content',
        additionalElement: <><img src="/assets/imgs/side-image-ctv.png" alt="Logotipo" className="logo" /></>
        //additionalElement: <><img src="/assets/imgs/Logotipo-03.png" alt="Logotipo" className="logo" /><div className="background-text">Registre-se agora!</div></>
      });
    }
  }, [location.pathname]);

  return (
    <div className={divContent.className}>
      {divContent.additionalElement}
    </div>
  );
}

function App() {
  return (
    <Router>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-4 d-none d-md-block position-relative background-image-container p-none">
            <DivBackgroundImage/>
            
            <div className="background-mask">
              <DivBackgroundContent/>
            </div>
            
          </div>

          <div className="col-md-8 mh-100vh no-padding">
            <DivBackgroundImageHeader/>

            <div className="form-container">
              <Routes>
                <Route path="/" element={<Step1 />} />
                <Route path="/step2" element={<Empty />} />
                <Route path="/step3" element={<Step3 />} />
                <Route path="/step4" element={<Step4 />} />
                <Route path="/step5" element={<Step5 />} />
                <Route path="/step6" element={<Step6 />} />
                <Route path="/step7" element={<Step7 />} />
                <Route path="/step8" element={<Step8 />} />
                <Route path="/step9" element={<Step9 />} />
                <Route path="/step10" element={<Step10 />} />
                <Route path="/thanks" element={<Thanks />} />
                <Route path="/signature" element={<Signature />} />
                <Route path="/launch" element={<Launch />} />
                <Route path="*" element={<Navigate to="/" replace />} />
              </Routes>
              <Step2 />
            </div>
          </div>
        </div>
      </div>
    </Router>
  );
}

export default App;
