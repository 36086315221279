import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaArrowLeft } from 'react-icons/fa';

const Signature = () => {
  const [isChecked, setIsChecked] = useState(false);
  const [isSigned, setIsSigned] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  const navigate = useNavigate();
  const signature = JSON.parse(localStorage.getItem('signature'));

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const handleFinalizarClick = () => {
    const signatureAdd = {
      token: signature.token,
      sign_url: signature.sign_url,
      term_of_acceptance: (isChecked === true ? 'Sim' : 'Não')
    };

    localStorage.setItem('signature', JSON.stringify(signatureAdd));

    navigate('/launch');
  };

  useEffect(() => {
    const handleMessage = (event) => {
      setIsSigned(event.data === 'zs-signed-file-ready');
      setIsVisible(event.data === 'zs-signed-file-ready');
    };

    window.addEventListener('message', handleMessage);

    return () => window.removeEventListener('message', handleMessage);
  }, []);

  return (
    <form style={{ width: '90%' }}>
      <div className="form-group">
        {
          signature?.sign_url ? (
            <div>
              <iframe
                src={signature?.sign_url}
                title="Assinatura Eletrônica"
                width="100%"
                height="800px"
                allow="camera"
              />
              <div className="container">
                <label className="label">
                  <input
                    className="checkbox"
                    type="checkbox"
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                  />
                  <span className="text">Aceito os termos e estatuto da Cooperativa.</span>
                </label>
              </div>
            </div>
          )
            :
            (
              <p>URL de assinatura eletrônica não encontrada.</p>
            )
        }
      </div>

      <div className="form-group button-group" style={{ marginTop: 20, textAlign: 'right' }}>
        <button
          type="button"
          onClick={() => {
            navigate('/step10');
          }}
          className="btn btn-secondary"
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginRight: '15px',
          }}
        >
          <FaArrowLeft style={{ marginRight: '5px' }} /> Voltar
        </button>

        {isVisible &&
          <button
            type="submit"
            onClick={handleFinalizarClick}
            className="btn btn-primary"
            hi
            disabled={!isChecked || !isSigned}
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
          >
            Finalizar
          </button>
        }
      </div>
    </form>
  );
};

export default Signature;
