import React, { useState, useEffect } from 'react';
import { useFormik, FieldArray, FormikProvider } from 'formik';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { FaArrowRight, FaMinus, FaPlus, FaArrowLeft } from 'react-icons/fa';

const Step3 = () => {
	const navigate = useNavigate();
	const [showTruckOptions, setShowTruckOptions] = useState(false);
	const [initialValues, setinitialValues] = useState({
		user_type: '',
		equipmentList: []
	});

	const formik = useFormik({
		initialValues: initialValues,
		enableReinitialize: true,
		validationSchema: Yup.object().shape({
			user_type: Yup.string().required('Campo obrigatório'),
			equipmentList: Yup.array().of(
				Yup.object().shape({
					equipment: Yup.string().required('Campo obrigatório'),
					capacity: Yup.string().when('equipment', {
						is: 'Guincho',
						then: schema => schema.required('Campo obrigatório'),
						otherwise: schema => schema.notRequired()
					}),
					truck: Yup.string().when('equipment', {
						is: (equipment) => ['Cegonha', 'Guincho'].includes(equipment),
						then: schema => schema.required('Campo obrigatório'),
						otherwise: schema => schema.notRequired()
					}),
					trailer: Yup.string().when('equipment', {
						is: 'Cegonha',
						then: schema => schema.required('Campo obrigatório'),
						otherwise: schema => schema.notRequired()
					}),
					others: Yup.string().when('equipment', {
						is: 'Outros',
						then: schema => schema.required('Campo obrigatório'),
						otherwise: schema => schema.notRequired()
					})
				})
			).when('user_type', {
				is: 'Transportador',
				then: schema => schema.min(1, 'Adicione pelo menos um equipamento'),
				otherwise: schema => schema.notRequired()
			})
		}),
		onSubmit: values => {
			localStorage.setItem('step3', JSON.stringify(values));

			if (values.user_type !== 'Agenciador') {
				navigate('/step4');	
			} else {
				localStorage.setItem('step4', JSON.stringify({hasTracker: '', tracker: ''}));
				localStorage.setItem('step5', JSON.stringify({company: '', fleet: ''}));

				navigate('/step6');
			}
		}
	});

	const handleUserTypeChange = async (event) => {
		const user_type = event.target.value;
		await formik.setFieldValue('user_type', user_type);
		await formik.setFieldTouched('user_type', true);
		if (user_type === 'Agenciador') {
			formik.setFieldValue('equipmentList', []);
		}
		setShowTruckOptions(user_type === 'Transportador');
	}; 

	const getStepData = () => {
		let step_data = localStorage.getItem('step3');

		if (step_data !== null) {
			const newInitialValues = JSON.parse(step_data);
			if (newInitialValues.user_type === 'Transportador') {
				setShowTruckOptions(true);
			}
			setinitialValues(newInitialValues);
		}
	}

	useEffect(()=>{
		getStepData();
	}, [])

	return (
		<FormikProvider value={formik}>
			<form onSubmit={formik.handleSubmit} style={{width: '90%'}}>
				<h2>Informações de Atuação</h2>
				<div className="form-group">
					<label htmlFor="user_type">Ramo</label>
					<select
						id="user_type"
						name="user_type"
						className="form-control"
						onChange={handleUserTypeChange}
						value={formik.values.user_type}
					>
						<option value="" label="Selecione o ramo" />
						<option value="Agenciador" label="Agenciador" />
						<option value="Transportador" label="Transportador" />
					</select>
					{formik.touched.user_type && formik.errors.user_type ? <div className="text-danger">{formik.errors.user_type}</div> : null}
				</div>
				{showTruckOptions && (
					<FieldArray name="equipmentList">
						{({ remove, push }) => (
							<div>
								{formik.values.equipmentList.map((equipment, index) => (
									<div key={index} className="form-group equipament-container">
										<div className="form-row">
											<div className="col">
												<label>Equipamento</label>
												<div className='row'>
													<div className='col no-padding-left'>
														<div className="btn-group">
															<button
																type="button"
																className={`btn-check ${equipment.equipment === 'Guincho' ? 'selected' : ''}`}
																onClick={() => [ 
																	formik.setFieldValue(`equipmentList.${index}.equipment`, 'Guincho'),
																	formik.setFieldValue(`equipmentList.${index}.capacity`, ''),
																	formik.setFieldValue(`equipmentList.${index}.truck`, ''),
																	formik.setFieldValue(`equipmentList.${index}.trailer`, ''),
																	formik.setFieldValue(`equipmentList.${index}.others`, '')
																]}
																aria-expanded="false"
																aria-haspopup="true"
															>
																<img src='/assets/imgs/guincho.png' alt="Guincho" />
																<p>Guincho</p>
															</button>

															<button
																type="button"
																className={`btn-check ${equipment.equipment === 'Cegonha' ? 'selected' : ''}`}
																onClick={() => [
																	formik.setFieldValue(`equipmentList.${index}.equipment`, 'Cegonha'), 
																	formik.setFieldValue(`equipmentList.${index}.capacity`, ''),
																	formik.setFieldValue(`equipmentList.${index}.truck`, ''),
																	formik.setFieldValue(`equipmentList.${index}.trailer`, ''),
																	formik.setFieldValue(`equipmentList.${index}.others`, '')
																]}
																aria-expanded="false"
																aria-haspopup="true"
															>
																<img src='/assets/imgs/cegonha.png' alt="Cegonha" />
																<p>Cegonha</p>
															</button>

															<button
																type="button"
																className={`btn-check ${equipment.equipment === 'Outros' ? 'selected' : ''}`}
																onClick={() => [
																	formik.setFieldValue(`equipmentList.${index}.equipment`, 'Outros'), 
																	formik.setFieldValue(`equipmentList.${index}.capacity`, ''),
																	formik.setFieldValue(`equipmentList.${index}.truck`, ''),
																	formik.setFieldValue(`equipmentList.${index}.trailer`, ''),
																	formik.setFieldValue(`equipmentList.${index}.others`, '')
																]}
																aria-expanded="false"
																aria-haspopup="true"
															>
																<img src='/assets/imgs/outros.png' alt="Outros" />
																<p>Outros</p>
															</button>

														</div>
													</div>
												</div>

												{equipment.equipment === 'Guincho' && (
													<div className='row'>
														<div className='col no-padding-left'>
															<div className='btn-group'>
																<button
																	type="button"
																	className={`btn-placa ${equipment.equipment === 'Guincho'}`}
																	aria-expanded="false"
																	aria-haspopup="true"
																>
																	<input
																		id={`equipmentList[${index}].truck`}
																		name={`equipmentList[${index}].truck`}
																		type="text"
																		className="form-control"
																		maxLength="7"
																		onChange={formik.handleChange}
																		value={equipment.truck}
																	/>
																</button>
															</div>
														</div>

														{
															formik.errors.equipmentList &&
															formik.errors.equipmentList[index] &&
															formik.errors.equipmentList[index].truck &&
															formik.touched.equipmentList &&
															formik.touched.equipmentList[index] &&
															formik.touched.equipmentList[index].truck ? (
																<div className="text-danger">
																	{formik.errors.equipmentList[index].truck}
																</div>
															) : null
														}

													</div>
												)}

												{
													formik.errors.equipmentList && 
													formik.errors.equipmentList[index] && 
													formik.errors.equipmentList[index].equipment ? (
														<div className="text-danger">
															{formik.errors.equipmentList[index].equipment}
														</div>
													) : null
												}

											</div>

											<div className="col-auto remove-button">
												<button type="button" className="btn btn-danger" onClick={() => remove(index)}>
													<FaMinus />
												</button>
											</div>
										</div>

										{equipment.equipment === 'Guincho' && (
											<div className="form-group">
												<div className='row'>
													<div className='col no-padding-left'>
														<div className="btn-group">
															<button
																type="button"
																className={`btn-check ${equipment.capacity === '1' ? 'selected' : ''}`}
																onClick={() => formik.setFieldValue(`equipmentList.${index}.capacity`, '1')}
																aria-expanded="false"
																aria-haspopup="true"
															>
																<img src='/assets/imgs/guincho_1_carro.png' alt="1 Carro" />
																<p>1 Carro</p>
															</button>

															<button
																type="button"
																className={`btn-check ${equipment.capacity === '2' ? 'selected' : ''}`}
																onClick={() => formik.setFieldValue(`equipmentList.${index}.capacity`, '2')}
																aria-expanded="false"
																aria-haspopup="true"
															>
																<img src='/assets/imgs/guincho_2_carros.png' alt="2 Carros" />
																<p>2 Carros</p>
															</button>

															<button
																type="button"
																className={`btn-check ${equipment.capacity === '3' ? 'selected' : ''}`}
																onClick={() => formik.setFieldValue(`equipmentList.${index}.capacity`, '3')}
																aria-expanded="false"
																aria-haspopup="true"
															>
																<img src='/assets/imgs/guincho_3_carros.png' alt="3 Carros" />
																<p>3 Carros</p>
															</button>

														</div>
													</div>
												</div>

												{
													formik.errors.equipmentList && 
													formik.errors.equipmentList[index] && 
													formik.errors.equipmentList[index].capacity ? (
														<div className="text-danger">
															{formik.errors.equipmentList[index].capacity}
														</div>
													) : null
												}
												
											</div>
										)}

										{equipment.equipment === 'Cegonha' && (
											<div className="form-group">
												<label htmlFor={`equipmentList.${index}.equipment`}>Tipo de Caminhão</label>
												<div className='row'>
													<div className='col no-padding-left'>
														<div className="btn-group">
															<button
																type="button"
																className={`btn-check ${equipment.truck !== '' ? 'selected' : ''}`}
																aria-expanded="false"
																aria-haspopup="true">

																<img src='/assets/imgs/cavalo.png' alt="Cavalo" />
																<p>Cavalo</p>
															</button>
															
															<button
																type="button"
																className={`btn-check ${equipment.trailer !== '' ? 'selected' : ''}`}
																aria-expanded="false"
																aria-haspopup="true">

																<img src='/assets/imgs/carreta.png' alt="Carreta" />
																<p>Carreta</p>
															</button>
														</div>
													</div>
												</div>

												<div className='row'>
													<div className='col no-padding-left'>

														<div className='btn-group'>
															<button
																type="button"
																className="btn-placa"
																aria-expanded="false"
																aria-haspopup="true"
															>
																<input
																	id={`equipmentList[${index}].truck`}
																	name={`equipmentList[${index}].truck`}
																	type="text"
																	className="form-control"
																	maxLength="7"
																	onChange={formik.handleChange}
																	value={equipment.truck}
																/>
															</button>
														</div>

														<div className='btn-group'>
															<button
																type="button"
																className="btn-placa"
																aria-expanded="false"
																aria-haspopup="true"
															>
																<input
																	id={`equipmentList[${index}].trailer`}
																	name={`equipmentList[${index}].trailer`}
																	type="text"
																	className="form-control"
																	maxLength="7"
																	onChange={formik.handleChange}
																	value={equipment.trailer}
																/>
															</button>
														</div>
													</div>
												</div>

												<div class="row">
													<div className='col no-padding-left'>

														<div className='btn-group'>
															<button
																type="button"
																className="btn-campo-obrigatorio"
																style={{marginLeft: '6px'}}
																aria-expanded="false"
																aria-haspopup="true"
															>
																{
																	formik.errors.equipmentList &&
																		formik.errors.equipmentList[index] &&
																		formik.errors.equipmentList[index].truck &&
																		formik.touched.equipmentList &&
																		formik.touched.equipmentList[index] &&
																		formik.touched.equipmentList[index].truck ? (
																		<div className="text-danger">
																			{formik.errors.equipmentList[index].truck}
																		</div>
																	) : null
																}
															</button>
														</div>

														<div className='btn-group'>
															<button
																type="button"
																className="btn-campo-obrigatorio"
																style={{marginLeft: '-7px'}}
																aria-expanded="false"
																aria-haspopup="true"
															>

																{
																	formik.errors.equipmentList &&
																		formik.errors.equipmentList[index] &&
																		formik.errors.equipmentList[index].trailer &&
																		formik.touched.equipmentList &&
																		formik.touched.equipmentList[index] &&
																		formik.touched.equipmentList[index].trailer ? (
																		<div className="text-danger">
																			{formik.errors.equipmentList[index].trailer}
																		</div>
																	) : null
																}

															</button>
														</div>
													</div>
												</div>
											</div>
										)}

										{equipment.equipment === 'Outros' && (
											<div className='row'>
												<div className='col no-padding-left'>
													<div className='btn-group'>
														<button
															type="button"
															className={'btn-outros'}
															aria-expanded="false"
															aria-haspopup="true"
														>
															<input
																id={`equipmentList[${index}].others`}
																name={`equipmentList[${index}].others`}
																type="text"
																className="form-control"
																maxLength="50"
																placeholder='Digite o tipo de equipamento'
																onChange={formik.handleChange}
																value={equipment.others}
															/>
														</button>
													</div>
												</div>

												{
													formik.errors.equipmentList &&
														formik.errors.equipmentList[index] &&
														formik.errors.equipmentList[index].others &&
														formik.touched.equipmentList &&
														formik.touched.equipmentList[index] &&
														formik.touched.equipmentList[index].others ? (
														<div className="text-danger">
															{formik.errors.equipmentList[index].others}
														</div>
													) : null
												}

											</div>
										)}

									</div>
								))}

								{
									formik.errors.equipmentList && 
									typeof formik.errors.equipmentList === 'string' ? (
										<div className="text-danger">
											{formik.errors.equipmentList}
										</div>
									) : null
								}

								<button type="button" className="btn btn-primary" onClick={() => push({ 
										equipment: '', 
										capacity: '',
										truck : '',
										trailer: '',
										others: ''
									})}>
									<FaPlus style={{ marginRight: '5px' }} /> Adicionar Equipamento
								</button>

							</div>
						)}
					</FieldArray>
				)}

				<div className="form-group button-group" style={{ marginTop: 20, textAlign: 'right' }}>
					
					<button type="button" onClick={()=>{ navigate('/step2'); }} className="btn btn-secondary" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginRight: '15px' }}>
						<FaArrowLeft style={{ marginRight: '5px' }} /> Voltar 
					</button>

					<button type="submit" className="btn btn-primary" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
						Próximo <FaArrowRight style={{ marginLeft: '5px' }} />
					</button>

				</div>

			</form>
		</FormikProvider>
	);
};

export default Step3;
