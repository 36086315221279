import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { FaArrowRight, FaArrowLeft } from 'react-icons/fa';

const Step5 = () => {

  const navigate = useNavigate();
  const [initialValues, setinitialValues] = useState({
    company: ''
  });  

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: Yup.object().shape({
    }),
    enableReinitialize: true,
    onSubmit: values => {
      localStorage.setItem('step5', JSON.stringify(values));
      navigate('/step6');
    }
  });

  const getStepData = () => {
    let step_data = localStorage.getItem('step5');

    if ( step_data !== null ) {
      setinitialValues(JSON.parse(step_data));
    }
  }

  useEffect(()=>{
    getStepData();
  }, [])

  return (
    <form onSubmit={formik.handleSubmit} style={{width: '90%'}}>
      <h2>Informações Empresa</h2>
      <div className="form-group">
        <label htmlFor="company">Trabalha em alguma dessas empresas?</label>
        <select
          id="company"
          name="company"
          className="form-control"
          onChange={formik.handleChange}
          value={formik.values.company}
        >
          <option value="" label="Selecione" />
          <option value="Autoport" label="Autoport" />
          <option value="Brazul" label="Brazul" />
          <option value="Dacunha Transportes" label="Dacunha Transportes" />
          <option value="Gabardo" label="Gabardo" />
          <option value="SADA Transportes e Armazenagens" label="SADA Transportes e Armazenagens" />
          <option value="Tegma Gestão Logística" label="Tegma Gestão Logística" />
          <option value="Transauto" label="Transauto" />
          <option value="Translor" label="Translor" />
          <option value="Transmoreno" label="Transmoreno" />
          <option value="Transzero" label="Transzero" />
        </select>

      </div>

      {formik.values.company !== '' && (
        <div className="form-group">
          <label htmlFor="fleet">Frota</label>
          <input
            id="fleet"
            name="fleet"
            type="text"
            className="form-control uppercase"
            maxLength="50"
            onChange={formik.handleChange}
            value={formik.values.fleet}
          />
        </div>
      )}

      <div className="form-group button-group" style={{ marginTop: 20, textAlign: 'right' }}>
        <button type="button" onClick={() => { navigate('/step4'); }} className="btn btn-secondary" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginRight: '15px' }}>
          <FaArrowLeft style={{ marginRight: '5px' }} /> Voltar
        </button>

        <button type="submit" className="btn btn-primary" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          Próximo <FaArrowRight style={{ marginLeft: '5px' }} />
        </button>

      </div>
    </form>
  );
};

export default Step5;
