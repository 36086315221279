import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import InputMask from 'react-input-mask';
import { FaArrowRight, FaArrowLeft } from 'react-icons/fa';

const Step9 = () => {
  const navigate = useNavigate();
  const [initialValues, setInitialValues] = useState({
    whatsapp: '',
  });

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: Yup.object({
        whatsapp: Yup.string().required('Campo obrigatório'),
    }),
    onSubmit: (values) => {
      localStorage.setItem('step9', JSON.stringify(values));
      navigate('/step10');
    },
  });

  const getStepData = () => {
    let step_data = localStorage.getItem('step9');
    if (step_data !== null) {
      const newInitialValues = JSON.parse(step_data);
      setInitialValues(newInitialValues);
    }
  };

  useEffect(() => {
    getStepData();
  }, []);

  return (
    <form onSubmit={formik.handleSubmit} style={{ width: '90%' }}>
      <h2>Contato</h2>
      <div className="form-group">
        <label htmlFor="whatsapp">Qual o seu Whatsapp para contato?</label>
        <InputMask
          id="whatsapp"
          name="whatsapp"
          type="text"
          mask="(99) 99999-9999"
          maskChar="_"
          className="form-control"
          onChange={formik.handleChange}
          value={formik.values.whatsapp}
        />
        {formik.errors.whatsapp ? <div className="text-danger">{formik.errors.whatsapp}</div> : null}
      </div>
      <div className="form-group button-group" style={{ marginTop: 20, textAlign: 'right' }}>
        <button
          type="button"
          onClick={() => {
            navigate('/step8');
          }}
          className="btn btn-secondary"
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginRight: '15px',
          }}
        >
          <FaArrowLeft style={{ marginRight: '5px' }} /> Voltar
        </button>
        <button
          type="submit"
          className="btn btn-primary"
          style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        >
          Próximo <FaArrowRight style={{ marginLeft: '5px' }} />
        </button>
      </div>
    </form>
  );
};

export default Step9;
