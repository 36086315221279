import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { FaArrowRight, FaArrowLeft } from 'react-icons/fa';

const Step4 = () => {

  const navigate = useNavigate();
  const [initialValues, setinitialValues] = useState({
    hasTracker: ''
  });  

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: Yup.object({
      hasTracker: Yup.string().required('Campo obrigatório'),
      tracker: Yup.string().when('hasTracker', {
        is: 'Sim',
        then: schema => schema.required('Campo obrigatório'),
        otherwise: schema => schema.notRequired()
      }),
    }),
    enableReinitialize: true,
    onSubmit: values => {
      localStorage.setItem('step4', JSON.stringify(values));
      navigate('/step5');
    }
  });

  const handleHasTrackerChange = async (event) => {
		const hasTracker = event.target.value;
		await formik.setFieldValue('hasTracker', hasTracker);
		await formik.setFieldTouched('hasTracker', true);

    formik.setFieldValue('tracker', '');

		if (hasTracker === 'Sim') {
      formik.setFieldTouched('tracker', true);
		}
	}; 

  const getStepData = () => {
    let step_data = localStorage.getItem('step4');

    if ( step_data !== null ) {
      setinitialValues(JSON.parse(step_data));
    }
  }

  useEffect(()=>{
    getStepData();
  }, [])

  return (
    <form onSubmit={formik.handleSubmit} style={{width: '90%'}}>
      <h2>Informações Rastreador</h2>
      <div className="form-group">
        <label htmlFor="hasTracker">Possui rastreador?</label>
        <select
          id="hasTracker"
          name="hasTracker"
          className="form-control"
          onChange={handleHasTrackerChange}
          value={formik.values.hasTracker}
        >
          <option value="" label="Selecione" />
          <option value="Sim" label="Sim" />
          <option value="Não" label="Não" />
        </select>

        {
          formik.touched.hasTracker && 
          formik.errors.hasTracker ? 
            <div className="text-danger">
              {formik.errors.hasTracker}
            </div> : null
        }
      </div>

      {formik.values.hasTracker === 'Sim' && (
        <div className="form-group">
          <label htmlFor="tracker">Qual?</label>
          <select
            id="tracker"
            name="tracker"
            className="form-control"
            onChange={formik.handleChange}
            value={formik.values.tracker}
          >
            <option value="" label="Selecione" />
            <option value="Autotrac" label="Autotrac" />
            <option value="Coban" label="Coban" />
            <option value="Cobli" label="Cobli" />
            <option value="Omnilink" label="Omnilink" />
            <option value="OnixSat" label="OnixSat" />
            <option value="Sascar" label="Sascar" />
            <option value="Zatix (Omnilink)" label="Zatix (Omnilink)" />
          </select>

          {
            formik.touched.tracker &&
              formik.errors.tracker ?
              <div className="text-danger">
                {formik.errors.tracker}
              </div> : null
          }

        </div>
      )}
  
      <div className="form-group button-group" style={{ marginTop: 20, textAlign: 'right' }}>
        <button type="button" onClick={() => { navigate('/step3'); }} className="btn btn-secondary" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginRight: '15px' }}>
          <FaArrowLeft style={{ marginRight: '5px' }} /> Voltar
        </button>

        <button type="submit" className="btn btn-primary" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          Próximo <FaArrowRight style={{ marginLeft: '5px' }} />
        </button>
      </div>

    </form>
  );
};

export default Step4;
