import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { FaArrowRight, FaArrowLeft } from 'react-icons/fa';
import * as Yup from 'yup';
import axios from 'axios';

const Step10 = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [initialValues, setInitialValues] = useState({
    email: '',
  });

  async function isCoOwner() {
    let step3 = JSON.parse(localStorage.getItem('step3'));
    let step6 = JSON.parse(localStorage.getItem('step6'));
    let step7 = JSON.parse(localStorage.getItem('step7'));

    step6.cpf = step6.cpf.replace(/\D/g, '');
    step6.cpf = `***${step6.cpf.slice(3, 6)}${step6.cpf.slice(6, 9)}**`

    step7.cnpj = step7.cnpj.replace(/\D/g, '');

    if (step3.user_type !== 'Transportador') {
      return false;
    }

    try {
      // Consulta CNPJ API
      const cnpjResponse = await axios.get(`https://publica.cnpj.ws/cnpj/${step7.cnpj}`);
      const socios = cnpjResponse.data.socios || [];
      const socioEncontrado = socios?.find(socio => socio.cpf_cnpj_socio === step6.cpf);

      if (!socioEncontrado) {
        return false;
      }

    } catch (error) {
      alert('Erro ao verificar se é um sócio.');
      return false;  
    }

    return true;
  }

  async function checkSign() {
    //Busca informações do sócio na API de CNPJ
    let step3 = JSON.parse(localStorage.getItem('step3'));
    let step6 = JSON.parse(localStorage.getItem('step6'));
    let step7 = JSON.parse(localStorage.getItem('step7'));
    let step9 = JSON.parse(localStorage.getItem('step9'));
    let step10 = JSON.parse(localStorage.getItem('step10'))

    step6.cpf = step6.cpf.replace(/\D/g, '');
    step6.cpf = `***${step6.cpf.slice(3, 6)}${step6.cpf.slice(6, 9)}**`

    step7.cnpj = step7.cnpj.replace(/\D/g, '');

    step9.whatsapp = step9.whatsapp.replace(/\D/g, '');

    if (step3.user_type !== 'Transportador') {
      return;
    }
    
    try {
      // Consulta CNPJ API
      const cnpjResponse = await axios.get(`https://publica.cnpj.ws/cnpj/${step7.cnpj}`);
      const razaoSocial = cnpjResponse.data.razao_social || {};
      const socios = cnpjResponse.data.socios || [];
      const socioEncontrado = socios?.find(socio => socio.cpf_cnpj_socio === step6.cpf);
      const estabelecimento = cnpjResponse.data.estabelecimento || {};
      let endereco = '';

      if (estabelecimento?.tipo_logradouro) {
        endereco = estabelecimento?.tipo_logradouro;
      }

      if (estabelecimento?.logradouro) {
        endereco = `${endereco} ${estabelecimento?.logradouro}`;
      }

      if (estabelecimento?.numero) {
        endereco = `${endereco}, ${estabelecimento?.numero}`;
      }

      if (estabelecimento?.complemento) {
        endereco = `${endereco}, ${estabelecimento?.complemento}`;
      }

      if (estabelecimento?.bairro) {
        endereco = `${endereco}, ${estabelecimento?.bairro}`;
      }

      if (estabelecimento?.cep) {
        if (estabelecimento.cep.length <= 8) {
          estabelecimento.cep = estabelecimento.cep.replace(/(\d{5})(\d{3})/, '$1-$2');
        }
    
        endereco = `${endereco}, ${estabelecimento?.cep}`;
      }

      if (estabelecimento?.cidade?.nome) {
        endereco = `${endereco}, ${estabelecimento?.cidade?.nome}`;
      }

      if (estabelecimento?.estado?.sigla) {
        endereco = `${endereco}/${estabelecimento?.estado?.sigla}`;
      }

      if (!socioEncontrado) {
        return;
      }

      // Criação de Documento ZapSign API
      const body = {
        //Produção
       template_id: 'cfe0823c-3d97-4414-8d56-d5565b078ed6',

        //Sandbox
        //template_id: 'd857d6c8-c786-454c-abae-2b1f764b52a5',

        signer_name: socioEncontrado.nome,
        signer_email: step10.email,
        signer_phone_country: '55',
        signer_phone_number: step9.whatsapp,
        send_automatic_email: false,
        send_automatic_whatsapp: false,
        lang: 'pt-br',
        disable_signer_emails: true,
        brand_logo: 'https://i.ibb.co/Cm1vqS4/Screenshot-2024-11-10-at-21-29-29.png',
        brand_primary_color: '#057E80',
        brand_name: 'CTV Logística',
        data: [
          {
            de: '{{NOME COMPLETO}}',
            para: socioEncontrado.nome
          },

          {
            de: '{{DATA}}',
            para: getCurrentDate()
          },

          {
            de: '{{CNPJ}}',
            para: step7.cnpj
          },

          {
            de: '{{EMPRESA}}',
            para: razaoSocial
          },

          {
            de: '{{ENDERECO}}',
            para: endereco
          },

          {
            de: '{{TELEFONE}}',
            para: step9.whatsapp
          },

          {
            de: '{{EMAIL}}',
            para: step10.email
          }
        ]
      };

      //const zapsignResponse = await axios.post('https://sandbox.api.zapsign.com.br/api/v1/models/create-doc', body, {
      const zapsignResponse = await axios.post('https://api.zapsign.com.br/api/v1/models/create-doc', body, {  
        headers: {
          'Content-Type': 'application/json', 
          //Produção
          'Authorization': 'Bearer fb4719f2-1344-4d28-9975-60f45d42cca65bb10659-36c5-473e-bb8f-34d09bb13ab1'
          
          //Sandbox
          //'Authorization': 'Bearer d2987f99-81c9-49d8-96cf-277556fb20e1549c0793-99cd-474e-8fd2-af76e229fe88'
        }
      });

      const signers = zapsignResponse.data.signers;

      if (signers && signers.length > 0) {
        const signature = {
          token: zapsignResponse.data.token,
          sign_url: signers[0].sign_url,
          term_of_acceptance: 'Não'
        }

        localStorage.setItem('signature', JSON.stringify(signature));
      };
      
    } catch (error) {
      alert('Erro ao realizar a assinatura eletrônica.');
    }
  }

  function getCurrentDate() {
    const date = new Date();
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: Yup.object({
      email: Yup.string().email('Endereço de e-mail inválido').required('Campo obrigatório')
    }),
    enableReinitialize: true,
    onSubmit: async (values) => {
      setLoading(true);

      localStorage.setItem('step10', JSON.stringify(values));

      if (await isCoOwner()) {
        await checkSign();

        navigate('/signature');  
      } else {
        const signature = {
          token: '',
          sign_url: '',
          term_of_acceptance: ''
        }

        localStorage.setItem('signature', JSON.stringify(signature));

        navigate('/launch');
      }

      setLoading(false);
    }
  });

  const getStepData = () => {
    let step_data = localStorage.getItem('step10');
    if (step_data !== null) {
      const newInitialValues = JSON.parse(step_data);
      setInitialValues(newInitialValues);
    }
  };

  useEffect(() => {
    getStepData();
  }, []);

  return (
    <form onSubmit={formik.handleSubmit} style={{ width: '90%' }}>
      <h2>Contato</h2>
      <div className="form-group">
        <label htmlFor="email">Qual o seu email para receber informações?</label>
        <input
          id="email"
          name="email"
          type="email"
          className="form-control"
          onChange={formik.handleChange}
          value={formik.values.email || ''}
        />
        {formik.errors.email ? <div className="text-danger">{formik.errors.email}</div> : null}
      </div>
      
      {
        loading && 
        <div className='spinner-overlay'>
          <div className="spinner"></div>
        </div>
      }

      <div className="form-group button-group" style={{ marginTop: 20, textAlign: 'right' }}>
        <button
          type="button"
          onClick={() => {
            navigate('/step9');
          }}
          className="btn btn-secondary"
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginRight: '15px',
          }}
        >
          <FaArrowLeft style={{ marginRight: '5px' }} /> Voltar
        </button>
        <button
          type="submit"
          className="btn btn-primary"
          style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        >
          Próximo <FaArrowRight style={{ marginLeft: '5px' }} />
        </button>
      </div>
    </form>
  );
};

export default Step10;
