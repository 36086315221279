import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { FaArrowRight } from 'react-icons/fa';

const Step1 = () => {

  const navigate = useNavigate();
  const [initialValues, setinitialValues] = useState({
    name: ''
  });  

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: Yup.object({
      name: Yup.string().required('Campo obrigatório')
    }),
    enableReinitialize: true,
    onSubmit: values => {
      localStorage.setItem('step1', JSON.stringify(values));
      navigate('/step2');
    }
  });

  const getStepData = () => {
    let step_data = localStorage.getItem('step1');

    if ( step_data !== null ) {
      setinitialValues(JSON.parse(step_data));
    }
  }

  useEffect(()=>{
    getStepData();
  }, [])

  return (
    <form onSubmit={formik.handleSubmit} style={{width: '90%'}}>
      <h2>Informações Pessoais</h2>
      <div className="form-group">
        <label htmlFor="name">Qual seu nome?</label>
        <input
          id="name"
          name="name"
          type="text"
          className="form-control"
          onChange={formik.handleChange}
          value={formik.values.name}
        />
        {formik.errors.name ? <div className="text-danger">{formik.errors.name}</div> : null}
      </div>
  
      <div className="form-group button-group" style={{ marginTop: 20, textAlign: 'right' }}>
        <button type="submit" className="btn btn-primary" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          Próximo <FaArrowRight style={{ marginLeft: '5px' }} />
        </button>
      </div>
    </form>
  );
};

export default Step1;
